<template>
  <div>
    <Modal :value="value" title="记录详情" @input="(val) => $emit('input', val)" :width="900" @on-visible-change="modalChange">
      <Form :label-width="150">
        <Row>
          <Col span="24">
          <FormItem label="检查图片">
            <template v-if="data.inspectPic&&data.inspectPic != ''&&data.inspectPic.indexOf(',')!=-1">
              <img v-for="(item,index) in data.inspectPic.split(',')" :key="index" :src="logSrc+item" alt="" width="100" height="100" @click="lookPicture(logSrc+item)" style="margin-right:10px" />
            </template>
            <img v-else-if="data.inspectPic != ''" :src="logSrc+data.inspectPic" alt="" width="100" height="100" @click="lookPicture(logSrc+data.inspectPic)" />
            <img v-else src="/imgs/empty/empty.png" width="100" height="100" alt="" />
          </FormItem>
          </Col>
          <Col span="24">
          <FormItem label="设备名称" prop="deviceName">
            <Input readonly placeholder="请输入设备名称" v-model="data.deviceName" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="24">
          <FormItem label="检查时间" prop="inspectTime">
            <DatePicker readonly type="date" placeholder="请选择检查时间" v-model="data.inspectTime" style="width: 80%"></DatePicker>
          </FormItem>
          </Col>
          <Col span="24">
          <FormItem label="检查人" prop="inspectPeople">
            <Input readonly placeholder="请输入检查人" v-model="data.inspectPeople" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="24">
          <FormItem label="检查人电话" prop="inspectTel">
            <Input readonly placeholder="请输入检查人电话" v-model="data.inspectTel" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="24">
          <FormItem label="是否损坏" prop="deviceStatus">
            <Select disabled placeholder="请选择是否损坏" v-model="data.deviceStatus" style="width: 80%">
              <Option value="0">否</Option>
              <Option value="1">是</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="24">
          <FormItem label="机器状况描述" prop="deviceCondition">
            <Input readonly type="textarea" :rows="4" placeholder="请输入机器状况描述" v-model="data.deviceCondition" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="24">
          <FormItem label="备注">
            <Input readonly type="textarea" :rows="4" placeholder="请输入备注" style="width: 80%" v-model="data.remarks"></Input>
          </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button @click="() => this.$emit('input', false)"> 关闭</Button>
      </p>
    </Modal>
    <pic-view v-model="picture.show" :src="picture.src"></pic-view>
  </div>
</template>

<script>
import picView from '@/components/picture_view'
export default {
  name: '',
  components: {
    picView,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    recordList: {
      type: Array,
      default: () => {
        return []
      },
    },
    farmersDetailInfo: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      data: {},
      checkPic: false,
      logSrc: '',
      picture: {
        show: false,
        src: '',
      },
    }
  },
  methods: {
    modalChange(validate) {
      this.data = this.farmersDetailInfo
    },
    //放大查看图片
    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      }
    },
  },
}
</script>

<style lang="less" scoped>
</style>